<!--
 * @Description: ================== 游戏介绍 =================
 * @Date: 2022-05-13 18:08:07
 * @Author: Zero
 * @FilePath: \guobao-web\src\components\Game.vue
 * @LastEditors: Zero
 * @LastEditTime: 2023-05-12 16:23:43
-->
<template>
  <div class="page page-game">
    <h2 class="title">游戏介绍</h2>
    <!-- <pre> -->
    <div class="content">
      <!-- <div class="content-item">
        <h4>《风暴之眼：讨鬼异闻录》</h4>
        《风暴之眼：讨鬼异闻录》是一款经典动漫卡牌放置手游，集结了多位高人气动漫人物。
        <img src="https://apk.najiahy.com/img/tgywlh5/www/1.png" alt="" />
      </div>
      <div class="content-item">
        <h4>【福利满满】</h4>
        八日登录即送稀有英雄角色，七日修行完成简易目标即可领取海量稀有奖励。
        <img src="https://apk.najiahy.com/img/tgywlh5/www/2.png" alt="" />
      </div>
      <div class="content-item">
        <h4>【资源丰厚】</h4>
        多种任务搭配，各类奖励拿不完。日常、周常、主线、历练任务，一次完成多个领取，钻石领到手软。
        <img src="https://apk.najiahy.com/img/tgywlh5/www/3.png" alt="" />
      </div>
      <div class="content-item">
        <h4>【炫酷技能】</h4>
        复刻原著动漫技能特效，炫酷的大招动画，给你带来沉浸式体验。
        <img src="https://apk.najiahy.com/img/tgywlh5/www/4.png" alt="" />
      </div> -->
      <p>游戏介绍：疾风秘闻？没错，就是疾风秘闻！忍者世界有太大太多各种各样的秘闻，比如地下龙洞、湿骨密林、苗木仙山等等秘境只是传闻中听说过，但没人知道他们在哪。但现在不一样了，你可以亲自到这些秘境中探索，所有秘闻你都可以亲历，在这里有强大的忍者陪你作战、在这里有强大的通灵兽等你召唤、在这里有强大的敌人等着你打败</p>
      <img src="https://apk.najiahy.com/img/jjjdir/2190.png" alt="疾风秘闻" />
    </div>
    <!-- </pre> -->
  </div>
</template>

<style scss>
.content-item {
  display: inline-block;
  width: 50%;
  padding: 12px;
  box-sizing: border-box;
  vertical-align: bottom;
}
@media screen and (max-width: 768px) {
  .content-item {width: 100%;}
}
.content-item img {
  display: block;
  margin-top: 12px;
}
</style>
