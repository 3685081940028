/*
 * @Description: ==================  =================
 * @Date: 2022-05-13 18:01:16
 * @Author: Zero
 * @FilePath: \guobao-web\src\main.js
 * @LastEditors: Zero
 * @LastEditTime: 2022-05-13 18:21:37
 */
import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import GameComponent from '@/components/Game.vue'
import ServiceComponent from '@/components/Service.vue'
import PrivacyComponent from '@/components/Privacy.vue'
import ContactComponent from '@/components/Contact.vue'
const router = createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHashHistory(),
  routes: [
    { path: '/', redirect: '/game' },
    { path: '/game', component: GameComponent },
    { path: '/service', component: ServiceComponent },
    { path: '/privacy', component: PrivacyComponent },
    { path: '/contact', component: ContactComponent }
  ] // `routes: routes` 的缩写
})

const app = createApp(App)
app.use(router)
app.mount('#app')
